import React from "react"
import { ListItem, ListItemText, Divider, Typography } from "@material-ui/core"
import { useAuthState } from "../hooks/use-auth-state"
import { Button, List, TextField } from "../components/my-styled"
import styled from "styled-components"
import SubmitExpense from "./submit-expense"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Item = styled.div`
  margin: 15px;
`
function Emails() {
  const { messages, state, setState, refreshExpenses } = useAuthState()
  return (
    <Container>
      <Item>
        <Button onClick={refreshExpenses}>Refresh expenses</Button>
      </Item>
      <Item>
        <Button
          onClick={() => {
            setState({
              submitExpenseItem: { location: "manual", cost: "" },
            })
          }}
        >
          File expense manually
        </Button>
      </Item>
      <Item>
        <TextField
          variant="outlined"
          label="Spreadsheet Id"
          value={state.spreadsheetId}
          onChange={(e) => {
            setState({ spreadsheetId: e.target.value })
          }}
        />
      </Item>
      {state && state.submitExpenseItem && <SubmitExpense />}
      <Item>
        {messages.length === 0 ? (
          <Typography variant="h4">No expenses to track!</Typography>
        ) : (
          <List>
            {(messages || []).map(({ location, cost, messageId }, index) => {
              return (
                <React.Fragment key={index}>
                  <ListItem
                    button
                    onClick={() => {
                      setState({
                        submitExpenseItem: { location, cost, messageId },
                      })
                    }}
                  >
                    <ListItemText
                      primary={`Location: ${location ? location : ""} Cost: ${
                        cost ? cost : ""
                      }`}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            })}
          </List>
        )}
      </Item>
    </Container>
  )
}

export default Emails
