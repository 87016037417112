import React from "react"
import firebase from "@firebase/app"
import "@firebase/auth"
import { Container } from "../components/my-styled"
import { Login as LoginForm } from "@mcrowder65/mooks"
const login = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

const Login = () => {
  const _onSubmit = async (event) => {
    try {
      event.preventDefault()
      const {
        email: { value: email },
        password: { value: password },
      } = event.target.elements
      await login(email, password)
    } catch (e) {
      alert("something went wrong :( ")
    }
  }

  return (
    <Container>
      <LoginForm _onSubmit={_onSubmit} />
    </Container>
  )
}

export default Login
