import React from "react"
import Emails from "./pages/emails"
import Login from "./pages/login"
import { Router } from "react-router-dom"
import { Route } from "react-router"
import { createBrowserHistory } from "history"
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "@firebase/app"
import { CircularProgress } from "@material-ui/core"
import PrivacyPolicy from "./pages/privacy-policy"
import { AuthState } from "./hooks/use-auth-state"
import { Container } from "./components/my-styled"
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles"
import { ThemeProvider } from "styled-components"

const styles = (theme) => ({
  "@global": {
    html: {
      background: theme.palette.background.default,
    },
  },
})
const Wrapper = withStyles(styles)((props) => props.children)
const theme = createMuiTheme()

const history = createBrowserHistory()
function App() {
  return (
    <Container>
      <Router history={history}>
        <Route
          exact
          path="/"
          component={() => (
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <Wrapper>
                  <AuthState>
                    {({
                      initializing,
                      user,
                      fetchingMessages,
                      initializingFirebase,
                    }) => {
                      if (initializing) {
                        return (
                          <Container>
                            <div>
                              <div>
                                {initializingFirebase &&
                                  "Initializing firebase..."}
                              </div>
                              <div>
                                {fetchingMessages && "Fetching expenses..."}
                              </div>
                            </div>
                            <div>
                              <CircularProgress />
                            </div>
                          </Container>
                        )
                      } else if (!user) {
                        return <Login />
                      }
                      return <Emails />
                    }}
                  </AuthState>
                </Wrapper>
              </ThemeProvider>
            </MuiThemeProvider>
          )}
        />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
      </Router>
    </Container>
  )
}

export default App
