import React from "react"
import styled from "styled-components"
import {
  Card as MuiCard,
  List as MuiList,
  Modal as MuiModal,
  TextField as MuiTextField,
  Button as MuiButton,
} from "@material-ui/core"

export const Button = styled((props) => (
  <MuiButton variant="contained" color="primary" {...props} />
))``
export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
`
export const TextField = styled((props) => (
  <MuiTextField variant="outlined" {...props} />
))`
  width: 350px;
`
export const Modal = styled(MuiModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`
Modal.defaultProps = {
  open: false,
}
export const Card = styled(MuiCard)`
  padding: 20px;
  width: 400px;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const List = styled(MuiList)`
  width: 100%;
  margin-top: 50px;
  max-width: 360px;
  background-color: ${(props) => props.theme.palette.background.paper};
`
