import React from "react"
import { useLocalStorageSetState } from "@mcrowder65/mooks"
import { useAuthState as useOtherAuthState } from "react-firebase-hooks/auth"
import firebase from "@firebase/app"
import "@firebase/auth"
import { getMessages } from "../api"
// Add the Firebase products that you want to use
firebase.initializeApp({
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
})
const AuthStateContext = React.createContext()
const reducer = (state, action) => {
  if (action.type === "SET_MESSAGES") {
    return {
      ...state,
      isLoading: false,
      messages: action.messages,
      sheets: action.sheets,
    }
  } else if (action.type === "REMOVE_MESSAGE") {
    return {
      ...state,
      messages: state.messages.filter((message) => {
        return message.messageId !== action.messageId
      }),
    }
  } else if (action.type === "REFRESHING") {
    return {
      ...state,
      messages: [],
      isLoading: true,
    }
  }
  return state
}

export const AuthState = ({ children }) => {
  const [messagesState, dispatch] = React.useReducer(reducer, {
    isLoading: true,
    messages: [],
    sheets: [],
  })
  const [refreshCount, setRefreshCount] = React.useState(0)
  const [user, initializing, error] = useOtherAuthState(firebase.auth())
  const [state, _setState] = useLocalStorageSetState(
    {
      spreadsheetId: "1ALZESb0dxJoAOLunIaydDvH-_IWZxd7zgOTQSor4ewY",
      submitExpenseItem: null,
      isManageSpreadsheetOpen: false,
    },
    "firebuddy.state",
    true,
  )

  const setState = React.useCallback(
    (newState) => _setState((oldState) => ({ ...oldState, ...newState })),
    [_setState],
  )
  React.useEffect(() => {
    const value = {
      spreadsheetId: state.spreadsheetId,
    }
    if (user) {
      getMessages(value).then((result) => {
        if (result.error) {
          alert(result.error)
        }
        dispatch({
          type: "SET_MESSAGES",
          messages: result.messages,
          sheets: result.sheets,
        })
      })
    } else if (!user && !initializing) {
      dispatch({ type: "SET_MESSAGES", messages: [], sheets: [] })
    }
  }, [state.spreadsheetId, user, initializing, refreshCount])
  const value = {
    user,
    state,
    setState,
    removeMessage: (messageId) =>
      dispatch({ type: "REMOVE_MESSAGE", messageId }),
    messages: messagesState.messages,
    sheets: messagesState.sheets,
    initializingFirebase: initializing,
    fetchingMessages: messagesState.isLoading,
    initializing: initializing === true || messagesState.isLoading === true,
    error,
    refreshExpenses: () => {
      dispatch({ type: "REFRESHING" })
      setRefreshCount((prev) => prev + 1)
    },
  }
  return (
    <AuthStateContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </AuthStateContext.Provider>
  )
}

export const useAuthState = () => {
  return React.useContext(AuthStateContext)
}
