import React from "react"
import styled from "styled-components"
import { InputLabel, FormControl as MuiFormControl } from "@material-ui/core"
import { Select as MuiSelect, MenuItem } from "@mui/material"
import {
  Modal,
  TextField,
  Button,
  Buttons,
  Card as OtherCard,
} from "../components/my-styled"
import { useAuthState } from "../hooks/use-auth-state"
import * as api from "../api"
import useLoader from "../hooks/use-loader"
const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const Select = styled(MuiSelect)`
  width: 250px;
`
const FormControl = styled(MuiFormControl)`
  margin: 20px;
  min-width: 120px;
`
const Card = styled(OtherCard)`
  height: 300px;
`
const SubmitExpense = () => {
  const {
    state: { submitExpenseItem: uncheckedExpense, spreadsheetId },
    sheets,
    setState,
    removeMessage,
  } = useAuthState()
  const isOpen = !!uncheckedExpense
  const close = () => setState({ submitExpenseItem: null })
  const expense = uncheckedExpense || {}
  const [chosenCategory, setChosenCategory] = React.useState({
    id: "",
    label: "",
    value: "",
  })
  const [location, setLocation] = React.useState(expense.location)
  const [cost, setCost] = React.useState(expense.cost)
  const [sheet, setSheet] = React.useState(
    (sheets || [])[0] || { categories: [] },
  )
  React.useEffect(() => {
    setLocation(expense.location)
    setCost(expense.cost)
    setSheet((sheets || [])[0] || { categories: [] })
  }, [expense.location, expense.cost, sheets])
  React.useEffect(() => {
    setChosenCategory(sheet.categories[0] || {})
  }, [sheet])

  React.useEffect(() => {
    if (expense.location === "manual") {
      setChosenCategory(sheet.categories.find((c) => c.label === "Gas"))
      setLocation("")
    }
  }, [expense, sheet])
  const [isArchiveEmailLoading, archiveExpenseApiCall] = useLoader(
    api.archiveEmail,
  )
  const archiveExpense = async () => {
    try {
      const result = await archiveExpenseApiCall({
        messageId: expense.messageId,
      })
      if (result.error) {
        alert(result.error)
      } else {
        removeMessage(expense.messageId)
        close()
      }
    } catch (error) {
      alert(error)
    }
  }
  const [isSubmitExpenseLoading, writeExpense] = useLoader(api.writeExpense)
  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const result = await writeExpense({
        spreadsheetId,
        sheet,
        location,
        cost,
        originalValue: chosenCategory.value,
        messageId: expense.messageId,
        cellLocation: chosenCategory.value,
      })
      if (result.error) {
        alert(result.error)
      } else {
        removeMessage(expense.messageId)
        close()
      }
    } catch (error) {
      alert(error)
    }
  }
  const isDisabled = isArchiveEmailLoading || isSubmitExpenseLoading
  return (
    <Modal open={isOpen}>
      <Card>
        <Form onSubmit={onSubmit}>
          <FormControl variant="filled" required>
            <InputLabel htmlFor="submit-expense-sheet">Sheet</InputLabel>
            <Select
              disabled={isDisabled}
              name="submit-expense-sheet"
              inputProps={{ id: "submit-expense-sheet" }}
              value={sheet && sheet.title}
              onChange={(e) => {
                setSheet(sheets.find((s) => s.title === e.target.value))
              }}
            >
              {(sheets || []).map((theSheet) => {
                return (
                  <MenuItem key={theSheet.title} value={theSheet.title}>
                    {theSheet.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl variant="filled" required>
            <InputLabel htmlFor="submit-expense-category">Category</InputLabel>
            <Select
              disabled={isDisabled}
              name="submit-expense-category"
              inputProps={{ id: "submit-expense-category" }}
              value={chosenCategory.label}
              onChange={(e) => {
                setChosenCategory(
                  sheet.categories.find((c) => c.label === e.target.value),
                )
              }}
            >
              {(sheet.categories || []).map((category) => {
                return (
                  <MenuItem key={category.label} value={category.label}>
                    {category.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <TextField
            disabled={isDisabled}
            label="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          <TextField
            disabled={isDisabled}
            label="Cost"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
          />
          <Buttons>
            <Button
              disabled={isDisabled}
              color="secondary"
              variant="text"
              onClick={close}
            >
              Cancel
            </Button>
            {expense.messageId ? (
              <Button disabled={isDisabled} onClick={archiveExpense}>
                Archive Expense
              </Button>
            ) : null}
            <Button disabled={isDisabled} type="submit">
              Save
            </Button>
          </Buttons>
        </Form>
      </Card>
    </Modal>
  )
}

SubmitExpense.defaultProps = {
  isOpen: false,
  expense: {},
}
export default SubmitExpense
